<template>
  <div>
    <div class="claimelly mx-auto" :class='[($root.d) ? "light" : "dark"]' style="min-height: 100vh;">
      <div class="row p-0 m-0">
        <div class="col-lg-2 col-md-3 side-bar p-0 m-0">
          <button class="mt-1" @click="initApp"> Create Service </button>
          <!-- <button class="mt-1" @click="updateStepStatus(0);appSetup = true;app = null;"> Create Service </button> -->
          <button class="mt-1" @click="logout"> Logout </button>
          <hr>
          <button disabled="disabled">Services</button>
          <button class="mt-1" :class="{'active':(app !== null && app._id == ap._id)}" 
          v-for="ap in apps" :key="ap._id" @click="app = ap;appSetup = true;">
             {{ ap.title }}
          </button>
        </div>
        <div class="col-lg-10 col-md-9 px-2 m-0 py-4" v-if="createApp == false && appSetup == false">
          <div>
         <div class="row">
          <div class="col-md-2">
        <button class="btn btn-success btn-sm w-100 d-block" @click="initApp"> Create Service </button>

          </div>
          <div class="col-md-10">
            <div>
            <b-input-group>
              <template #prepend>
                <b-input-group-text ><span class="pr-2">Include Test Networks</span>
                  <input type="checkbox"  name="" id="" v-model="testNetworks" value="test">

                </b-input-group-text>
              </template>
              <b-form-input v-model="search" placeholder="Type to search apps"></b-form-input>

              <template #append>
                <b-dropdown :text="filterValue" variant="success">
                  <b-dropdown-item @click="filter = 'all';filterValue = 'All';">All</b-dropdown-item>
                  <b-dropdown-item @click="filter = 'isPublished';filterValue = 'Published';">Published</b-dropdown-item>
                  <b-dropdown-item @click="filter = 'CONTRACT';filterValue = 'Deployed';">Deployemd-d</b-dropdown-item>
                  <b-dropdown-item @click="filter = 'merkleData';filterValue = 'List Submitted';">List Submitted</b-dropdown-item>
                  <b-dropdown-item @click="filter = 'TOKEN';filterValue = 'Token Submitted';">Token Submitted</b-dropdown-item>
                </b-dropdown>
              </template>
            </b-input-group>
          </div>   
          </div>
         </div>
      <div class="row" v-if="appsAll.progress.length || appsAll.completed.length">
 
              <div class="scrollable-container" ref="scrollContainer" @touchstart="handleTouchStart" @touchmove="handleTouchMove" @touchend="handleTouchEnd">
                <button class="scroll-button lefts" @click="scrollLeft"> 
              <img src="../assets/images/left.png" style="max-width:55px;margin-left: -1px;" alt="" srcset="">
              </button>
              <button class="scroll-button rights" @click="scrollRight"> 
                <img src="../assets/images/right.png" style="max-width:55px;margin-left: 0px;" alt="" srcset="">
              </button>
              <div class="scrollable-content" ref="content" >
                <div v-for="ap in appsAll.completed" :key="ap._id" class="d-inline-block pl-3" style="max-width:320px;">
                  <div class="mx-auto nft-back st-shadow" style="background: #FFFF;transform: scale(0.8);border-radius: 15px 15px 15px 15px;">
                    <div class="fixed-container" >
                      <b-card style="min-height:100%;">
                        <template #header>
      <h4 class="mb-0">{{(ap.title) ? ap.title : 'No Title'}}
        <span class="float-right" style="cursor: pointer;" @click="loadSetup(ap)">&#9881;</span>
      </h4>
      <span>{{(ap.selectedChain) ? ap.selectedChain.name : ''}}</span>
    </template>
                        
          <b-card-text>
            <p>Service Status <span class="float-right"> 
              <b v-if="ap.isPublished">Published <a :href="'/app/'+ap._id" target="_blank" rel="noopener noreferrer">&#x21AA;</a> </b>
              <b v-else>In Setup Progress</b>
            </span> </p>
            <p v-if="!setupComplete"> 
              Last Step:
              <span class="float-right"> 
              <b> {{ stepStatus[ap.currentStep] }} </b>
            </span>
            </p>
            <p > 
              Token:
              <span class="float-right" v-if="ap.symbol"> 
              <b> {{ ap.symbol }} </b>
            </span>
            </p>
            <p > 
              Token Contract:
              <span class="float-right" v-if="ap.TOKEN"> 
                <span> <b class="btn btn-link" @click='copy(ap.TOKEN)'> {{ ap.TOKEN.substring(0,4) }}....{{ ap.TOKEN.substr(ap.TOKEN.length - 4) }}  &#x2398;</b> </span>
              </span>
            </p>
            <p > 
              Claim Contract:
              <span class="float-right" v-if="ap.CONTRACT"> 
                <span> <b class="btn btn-link" @click='copy(ap.CONTRACT)'> {{ ap.CONTRACT.substring(0,4) }}....{{ ap.CONTRACT.substr(ap.CONTRACT.length - 4) }}  &#x2398;</b> </span>
              </span>
            </p>
            <p > 
              Token Deciamls:
              <span class="float-right" v-if="ap.DECIMALS"> 
              <b> {{ ap.DECIMALS }} </b>
            </span>
            </p>
            <p > 
              Total Claiments:
              <span class="float-right" v-if="ap.merkleData"> 
              <b> {{ CtotalClaimWallets(ap) }} </b>
            </span>
            </p>
            <p > 
              Total Funds Required:
              <span class="float-right text-dark" v-if="ap.merkleData"> 
              <b> {{ CtotalClaimBalance(ap) }}</b>
            </span>
            </p>
            <div>
            </div> 
          </b-card-text>
          <div >
            <!-- <button v-if="ap.currentStep == 4" class="btn btn-primary btn-sm mr-1" @click="publishApp(ap)">Publish Service</button> -->
            <!-- <button class="btn btn-primary btn-sm mr-1" @click="loadSetup(ap)">Manage</button> -->
            <div v-if="ap.isPublished"  class="mt-2">
              <button v-if="ap.widget"  class="btn btn-primary btn-sm mr-1" @click="getIFRAME(ap)">Generate IFRAME</button>
              <button v-if="ap.hosting" class="btn btn-primary btn-sm" @click="getURL(ap)">Get App URL</button>
            </div>
            

          </div>
        </b-card>
                    </div>
                  </div>
                </div>
              </div>
        </div>
        

        
      </div>
      
    </div>
        </div>
        <div class="col-lg-10 col-md-9" v-else>

          <div class="row justify-content-center py-4" v-if="appSetup">
  <span  style="cursor: pointer;
    position: absolute;
    left: 5px;
    font-size: 31px;
    color: red;
    font-weight: 900;"  @click="appSetup = false;">&#xab;</span>

            <app-container :app="app"></app-container>  
          </div>
          <div class="row justify-content-center py-4" v-if="createApp" >
  <span  style="cursor: pointer;
    position: absolute;
    left: 5px;
    font-size: 31px;
    color: red;
    font-weight: 900;"  @click="createApp = false;">&#xab;</span>
            <create-app></create-app>  
          </div>


  <b-modal id="all-details"
   size='lg'
  ok-only 
  title="Distrubution Contract Summary"
    >
    <h2>Summary</h2>
    <div v-if='userAccount'>
      
          <p v-if='userAccount.selectedChain !== null'>
            Network Chain Selected: 
            <b class="float-right"> {{userAccount.selectedChain}} </b>
          </p>
          <p >
            Distrubution Contract Address: 
            <b class="float-right"> {{userAccount.CONTRACT}} </b>
          </p>
         <p >
            Token Contract Address: 
            <b class="float-right"> {{userAccount.TOKEN}} </b>
          </p>
          <p >
            Distrubution Start Date: 
            <b class="float-right"> {{userAccount.startTime}} </b>
          </p>
          <p >
            Distrubution End Date: 
            <b class="float-right"> {{userAccount.endTime}} </b>
          </p>
          
          <p >
            Token Symbol: 
            <b class="float-right"> {{userAccount.symbol}} </b>
          </p>
          <p >
            Token Deciamls: 
            <b class="float-right"> {{userAccount.DECIMALS}} </b>
          </p>
          <p >
            Total Claimable Wallets: 
            <b class="float-right" v-if="totalClaimWallets"> {{totalClaimWallets}} </b>
            <b class="floa-right text-danger"> List not generated </b>

          </p>
          <p >
            Total Claimable Amount: 
            <b class="float-right" v-if="totalClaimBalance"> {{totalClaimBalance}} </b>
            <b class="floa-right text-danger"> List not generated </b>

          </p>
          <p >
            Owner/Admin of the DIstrubition Contract: 
            <b class="float-right"> {{userAccount.owner}} </b>
          </p>
    <button class="btn btn-primary" @click='showWallets = !showWallets'> 
    {{(showWallets) ? "Hide Wallets" : "Show Wallets"}}
    </button>
     <table class='table' v-if='showWallets'>
      <thead>
        <tr>
          <th>Wallet</th>
          <th>Value</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for='(wallet, key) in claimWallets' :key='key'>
          <td>
            {{key}}
          </td>
          <td>
            <!-- {{wallet}}{{key}} -->
            {{parseInt(wallet.amount,16)/10**DECIMALS}} {{symbol}}
            
          </td>
        </tr>
      </tbody>
    </table> 
    </div>
    
    
  </b-modal>
        </div>
      </div>
  <!-- <div v-if='currentStep == null' class="py-2" style="height: 50px;"> -->
      <!-- <button class="btn btn-primary float-right m-2" @click="updateStepStatus(0)"> Create Service </button> -->
      <!-- <button class="btn btn-primary float-right mx-1"> View All Apps </button> -->
<!-- </div> -->
<div>

</div>

    </div>
    
    
  </div>
</template>

<script>
    const Footer = () => import("../components/Footer.vue");
    const SmallCard = () => import("../components/SmallCard.vue");
    const WhitePaperComp = () => import("../components/WhitePaperComp.vue");
    const AppContainer = () => import("./AppContainer.vue");
    const CreateApp = () => import("./CreateApp.vue");
    import Web3 from 'web3';
const web3 = new Web3(Web3.givenProvider);
import detectEthereumProvider from '@metamask/detect-provider';
import {mapState} from 'vuex';
import { parseBalanceMap } from '../parse-balance-map.ts'

export default {
    components:{Footer,SmallCard,WhitePaperComp, CreateApp, AppContainer},
    computed:{
    ...mapState(["apps","user","selectedAccount","validationError","endTime","startTime","userAccount","DECIMALS","currentStep",
    'setupComplete','symbol','availableTokens','claimAccountNativeBalance','CONTRACT_ADDRESS',
    'claimable','isClaimDone','accounts','claimData','claimAccountBalance','TokenBalance','owner','admin']),
    isAtMinScrollPosition() {
      return this.scrollPosition <= 0;
    },
    isAtMaxScrollPosition() {
      const content = this.$refs.content;
      const viewportWidth = content.parentElement.offsetWidth;
      const contentWidth = content.scrollWidth;
      return this.scrollPosition + viewportWidth >= contentWidth;
    },
    appsAll(){
      let apps = {completed:[], progress:[]};
      this.apps.forEach(element => {
        if (this.filter == 'all') {
          apps.completed.push(element)
        } else {
          if(element[this.filter])
          apps.completed.push(element)
        }
      });
      let appsc = {completed:[], progress:[]};
      if(this.testNetworks){
        appsc = apps
      }else{
        apps.completed.forEach(element => {
          if(element.selectedChain.name.includes("Test") ){

          }else{
            appsc.completed.psuh(element)
          }
        });
      }
      let appsAll = {completed:[], progress:[]};
      if(this.search.length){
        appsc.completed.forEach(element => {
          if(element.title.toLowerCase().includes(this.search.toLocaleLowerCase()) ){
            appsAll.completed.push(element)
          }
        });
      }else{
        appsAll = appsc
      }
      return appsAll
    },
    totalClaimBalance(){
      if (this.app.merkleData) {
      let data = JSON.parse(this.app.merkleData)
      return (parseInt(data.tokenTotal,16)/10**this.DECIMALS+" "+this.symbol)
      }
    },
    totalClaimWallets(){
      if (this.app.merkleData) {
      let data = JSON.parse(this.app.merkleData)
      let count = Object.keys(data.claims);
      return count.length 
      }
    },
    claimWallets(){
      if (this.userAccount.merkleData) {
      let data = JSON.parse(this.userAccount.merkleData)
      return data.claims
      }
    },
    account(){
      return this.$store.state.registeredWallets[this.selectedAccount].claims[this.appId]
    },
    validateWToken(){
      let cond = web3.utils.isAddress(this.wToken)
      if (cond) {
        return false;
      } else {
        return true
      }
    },
    getClass(){
      return (this.mode) ? "light" : "dark";
    },
    validatedChangeAdmin(){
      let cond = web3.utils.isAddress(this.changeAdmin)
      if (cond) {
        return false;
      } else {
        return true
      }
    },
    validatedWalletCheck(){
      let cond = web3.utils.isAddress(this.vCheck)
      if (cond) {
        return false;
      } else {
        return true
      }
    },
    FCONTRACT_ADDRESS(){
      return this.CONTRACT_ADDRESS.substr(0,7)+"....."+this.CONTRACT_ADDRESS.substr(-8)
    },
    Fadmin(){
      return this.admin.substr(0,7)+"....."+this.admin.substr(-8)
    },
    Fowner(){
      return this.owner.substr(0,7)+"....."+this.owner.substr(-8)
    },
  },
  data() {
    return {
      filter:"all",
      filterValue:"All",
      testNetworks:true,
      search:"",
      portal:null,
      stepStatus:['Initialized','Token & Chain Info Submitted','Submitted Distribution List','Contract Deployed','Dates Are Set'],
      services:[
        {
          _id:1,
          name:"Token Claim Service"
        }
      ],
      chains:[
        {
          name:"Ethereum Main Network",
          chainId:"0x1",
          KEY:'3MBSDFSIQZ1B9WWYI1V7GY4MX9QFQE83XZ',
          API:"https://api.etherscan.io/"
        },
        {
          name:"Rinkeby Test Network",
          chainId:"0x4",
          KEY:'3MBSDFSIQZ1B9WWYI1V7GY4MX9QFQE83XZ',
          API:"https://api-rinkeby.etherscan.io/"
        },
        {
          name:"Binance Main Network",
          chainId:"0x38",
          KEY:'YU1IGKUWQDN49FP4T3YGV97VDV56HVG7AW',
          API:"https://api.bscscan.com/"
        },
        {
          name:"Binance Test Network",
          chainId:"0x61",
          KEY:'YU1IGKUWQDN49FP4T3YGV97VDV56HVG7AW',
          API:"https://api-testnet.bscscan.com/"
        }
      ],
      createApp:false,
      startDate:null,
      endDate:null,
      selectedChain:null,
      selectedService:{
          _id:1,
          name:"Token Claim Service"
        },
      showDetails:false,
      csvData:null,
      loading:false,
      mode:false,
      appSetup:false,
    fundAmount:0,
    walletCheckResults:null,
    vCheck:'',
    isAdmin:false,
    manualInput:false,
      changeAdmin:'',
      days:'',
      hours:'',
      web3:null,
      days:'',
      hours:'',
      minutes:'',
      seconds:'',
      countDownDate:new Date("Aug 23, 2022 4:14:25").getTime(),
      distance:null,
      now:null,
      expired:false,
      wAmount:0,
      fAmount:0,
      wAddress:null,
      wToken:null,
      loadingBQ:false,
      fileinput:null,
      errors:[],
      selectedToken:null,
      skipped:[],
      newTitle:"",
      isWalletValid:null,
      selectedSymbol:null,
      selectedDecimal:null,
      showWallets:false,
      provider:null,
      appTitle:"",
      validatedApp:true,
      app:null,
      appId:"0x3107f81e9d50a791718776a083eF529ED0b54252",
      touchStartX: 0,
      touchMoveX: 0,
      isSwiping: false,
    scrollPosition: 0,
      scrollAmount: 355,
      // wToken:"0x542f71A83dd4c11a56a9071d7301D6AdbFf9E346",
    }
  },
  
  async mounted() {
    if (this.$store.state.user) {
      this.$store.dispatch("getApps")      
    }else{
      if (this.$store.state.user) {
        await this.$store.dispatch("checkUser")        
      }else{
        this.$router.push({path:'/login'})
      }
    }
    // await this.$store.dispatch("loadAccounts")
    // await this.checkParams()
    // if (this.selectedAccount) {
      
    // }
},
// touchStartX,touchMoveX,isSwiping
  methods:{
    getIFRAME(ap){},
    getURL(ap){},
    scrollLeft() {
    console.log("left")
      if (!this.isAtMinScrollPosition) {
        this.scrollPosition -= this.scrollAmount;
        this.updateScroll();
      }
    },
    scrollRight() {
      console.log("right")
      if (!this.isAtMaxScrollPosition) {
        this.scrollPosition += this.scrollAmount;
        this.updateScroll();
      }
    },
    updateScroll() {
      const content = this.$refs.content;
      content.style.transform = `translateX(${-this.scrollPosition}px)`;
    },
    handleTouchStart(event) {
      this.touchStartX = event.touches[0].clientX;
      this.touchMoveX = this.touchStartX;
      this.isSwiping = true;
    },
    handleTouchMove(event) {
      if (this.isSwiping) {
        this.touchMoveX = event.touches[0].clientX;
        const deltaX = this.touchMoveX - this.touchStartX;
        this.$refs.scrollContainer.scrollLeft -= deltaX;
      }
    },
    handleTouchEnd() {
      this.isSwiping = false;
    },
    initApp(){
      // <button class="mt-1" @click="updateStepStatus(0);appSetup = true;app = null;"> Create Service </button>
      this.createApp = true;
    },
    async resetMerkleData(){
      let app = this.app;
      app.merkleData = "";
      this.app.merkleData = "";
      await this.$store.dispatch("updateAppData", {app});
      await this.$store.dispatch("getApps")      


    },
    async saveAppChanges(){
      if (this.isWalletValid) {
        if (this.selectedDecimal && this.selectedSymbol.length > 1 && this.selectedToken.length > 5 ) {
          let app = {};
            app._id = this.app._id
            app.DECIMALS = this.selectedDecimal;
            app.symbol = this.selectedSymbol;
            app.selectedChain = this.selectedChain;
            app.TOKEN = this.selectedToken;
            app.currentStep = this.currentStep+1;
      let dec = await this.$store.dispatch("updateAppData", {app:app, next: 4})
            if (dec.status) {
            this.$store.dispatch("getApps")
            this.app = dec.data;
            this.selectedToken = null;
            this.selectedSymbol = null;
            this.selectedDecimal = null;
            this.selectedChain = null;
            this.appTitle = '';
            }else{
          alert("Failed to create Service, server error")
            }            
        }else{
          alert("invalid Symbol or Decimals")
        }
        
      }else{
        alert("Token Contract Address is not valid")
      }
    },
    async resetNetwork(){
      let dec = confirm("this will reset the Network, token and Distribution list if any generated and can't be recovered")
      if(dec){
        let app = this.app
        app.currentStep = 0
        app.selectedChain = {}
        app.DECIMALS = ""
        app.TOKEN = ""
        app.owner = ""
        app.symbol = ""
        app.CONTRACT = ""
        this.app = app
        await this.$store.dispatch("updateAppData",{app})
        await this.$store.dispatch("getApps")      
      }
    },
    async updateAppProps(prop){
      if(prop == 'title'){
        if(this.newTitle.length < 5){
          alert("Title must be at least 5 characters")
        }else{
         let dec = await this.$store.dispatch("updateProps",{id:this.app._id, [prop]: this.newTitle, prop}) 
         if(dec.status){
          alert(prop,' updated')
          this.app.title = this.newTitle
         }
        }
      
      }
    },
    publishApp(app){
      let dec = confirm("This will publish app publically")
      if(dec){
        this.$store.dispatch("publishApp",{app: app._id})
      }
    },
    logout(){
      this.$store.dispatch("logout");
      this.$router.push({path:"/"})
    },
    loadApp(app){
      let link = location.origin
      link += '/app/'+app._id
      window.open(link)
    },
    CtotalClaimBalance(app){
      if (app.merkleData) {
      let data = JSON.parse(app.merkleData)
      return (parseInt(data.tokenTotal,16)/10**app.DECIMALS+" "+app.symbol)
      }
    },
    CtotalClaimWallets(app){
      if (app.merkleData) {
      let data = JSON.parse(app.merkleData)
      let count = Object.keys(data.claims);
      return count.length 
      }
    },
    
    backToApps(){
      this.app = null;
      this.appSetup = false;
      this.$store.state.currentStep = null;
    },
    loadSetup(app){
this.app = app;
this.$store.state.DECIMALS = this.app.DECIMALS;
this.$store.state.symbol = this.app.symbol;
this.updateStepStatus(this.app.currentStep);
this.appSetup = true;
    },
    updateStepStatus(step){
        this.$store.state.currentStep = step;
        if(step == 0) {
          this.newTitle = (this.app) ? this.app.title : ""
        }
    },
    stepBack(step){
        if (step == 0) {
        this.$store.state.registeredWallets[this.selectedAccount].claims[this.appId].DECIMALS = null
        this.$store.state.registeredWallets[this.selectedAccount].claims[this.appId].symbol = null
        this.$store.state.registeredWallets[this.selectedAccount].claims[this.appId].TOKEN = null
        this.$store.state.registeredWallets[this.selectedAccount].claims[this.appId].selectedChain = null
        }
      this.$store.dispatch("stepBack", {step, id:this.appId})

    },
    resetApp(){
      let dec = confirm("Are you sure? this will cause wipe of all current data plus loss of deployed smart contract")
      if (dec) {
        this.$store.dispatch("resetApp", this.appId)
      }
    },
    goToScan(CONTRACT,chainId){
      let network = '';
       if (chainId == "0x1") network = "https://etherscan.io/address/"
      if (chainId == "0x4") network = "https://rinkeby.etherscan.io/address/"
      if (chainId == "0x38") network = "https://bscscan.com/address/"
      if (chainId == "0x61") network = "https://testnet.bscscan.com/address/"
      window.open(network+CONTRACT, '_blank').focus();

    },
    async checkParams(){
      let params = this.$route.params;
      this.appId = params.id
      this.portal = params.location
      if (this.portal ==  "portal") {
        this.connect()
      }
    let dec = await this.$store.dispatch("loadAppConfig", params)
    if (dec) 
    {
      this.validatedApp = true
      if (this.startTime && this.endTime) {
        await this.timer();        
      }

    }
    else this.validatedApp = false

    },
    startApp(){
      this.$store.dispatch("startApp", this.selectedAccount)
    },
    loadDetails(){
        this.$bvModal.show("all-details")
    },
    async saveTimeChanges(){
      //check if end date is null or greater then start date 
      
      if (this.endDate && this.startDate) {
        let edr = false;
        let sdr = false;
        var d1 = new Date(this.startDate);
        var d2 = new Date(this.endDate);
        if (d2 > d1) {
          this.app.endTime = this.endDate
        //   this.$store.state.registeredWallets[this.selectedAccount].claims[this.appId].endTime = this.endDate
          edr = true
        }else{
          alert("End Date must be greater then Start Date")
        }
        //
        var d1 = new Date(this.startDate);
          var d2 = new Date();
          if (d1 > d2) {
          this.app.startTime = this.startDate
        //   this.$store.state.registeredWallets[this.selectedAccount].claims[this.appId].startTime = this.startDate
            sdr = true
          }else{
            alert("Start Date must be a future date")
          }

          if (sdr && edr) {
          this.app.currentStep = 4
      let dec = await this.$store.dispatch("updateAppData", {app:this.app, next: 4})

        //   this.$store.state.currentStep = 4
        //   this.$store.dispatch("updateAccounts")
            // alert("Date are configured now")
          }else{
            alert("Dates are not configured correctly, please check dates")
          }
      }
      else{
        alert("Please provide valid date")
      }
      // var same = d1.getTime() === d2.getTime();
      // var notSame = d1.getTime() !== d2.getTime();
    },
    async confirmDeploy(){
        if (this.selectedAccount) {
      const provider = await detectEthereumProvider();
            if (provider) {
        this.web3 = new Web3(provider);
        provider.enable();
        const chainId = await provider.request({
            method: 'eth_chainId'
          })
       if (chainId == this.app.selectedChain.chainId) {
        this.$store.dispatch("deployContract", {app:this.app, account:this.selectedAccount})
      }else{
        alert("Please change meta mask network to your Selected "+this.app.selectedChain.name+" and then retry")
      }   
      }
        }else{
            alert("Wallet Connectivity Required")
        }
         
          
    },
    async validateWallet(){
      this.loadingBQ = true;
      if (web3.utils.isAddress(this.selectedToken)) {
        console.log("passed test wallet")
        // let result = await fetch(this.selectedChain.API+'api?module=contract&action=getabi&address='+this.selectedToken+'&apikey='+this.selectedChain.KEY)
        // result = await result.json()
        let result = await this.$store.dispatch("BquerySymbolDecimals",{chain:this.selectedChain.chainId,address:this.selectedToken})
        console.log("result of BquerySymbolDecimals:",result)
        if (result) {
          this.loadingBQ = false
        this.isWalletValid = true
        this.selectedSymbol = result[0].smartContract.currency.symbol
        this.selectedDecimal = result[0].smartContract.currency.decimals
        }else{
          this.loadingBQ = false

          this.isWalletValid = true
        this.manualInput = true
        }
        // if (result.status !== '0') {
        // let contractABI = JSON.parse(result.result);
        // console.log(contractABI)  
        // }else{
        //   alert(result.result)
        
        // }
        
        return
        this.$store.dispatch("loadSymbolForm", {address:this.selectedToken, chain:this.selectedChain})
      } else {
        console.log("uinvalid wallet")
        this.loadingBQ = false

        this.isWalletValid = false
      }
    },
    async saveTokenChanges(){
      if (this.isWalletValid) {
        if (this.selectedDecimal && this.selectedSymbol.length > 1 && this.selectedToken.length > 5 && this.appTitle.length > 3 ) {
          this.app = {};
            this.app.service = this.selectedService;
            this.app.title = this.appTitle;
            this.app.DECIMALS = this.selectedDecimal;
            this.app.symbol = this.selectedSymbol;
            this.app.selectedChain = this.selectedChain;
            this.app.TOKEN = this.selectedToken;
            this.app.currentStep = this.currentStep+1;
            this.app.user = this.user._id;
            this.app.owner = this.selectedAccount;
            let dec = await this.$store.dispatch("createApp", {app: this.app})
            if (dec.status) {
    this.$store.dispatch("getApps")
                this.app = dec.data;
            this.$store.state.DECIMALS = this.selectedDecimal
            this.$store.state.symbol = this.selectedSymbol
            this.$store.state.currentStep = 1
            this.selectedToken = null;
            this.selectedSymbol = null;
            this.selectedDecimal = null;
            this.selectedChain = null;
            this.appTitle = '';
            }else{
          alert("Failed to create Service, server error")
            }            
        }else{
          alert("invalid Symbol or Decimals")
        }
        
      }else{
        alert("Token Contract Address is not valid")
      }
    },
    async handleFile(e){
      var files = e.target.files || e.dataTransfer.files;
      if (files.length){
        await this.createInput(files[0]);
        
      }else{
        console.log("Invalid file")
      }
        
    },
     async createInput(file) {
      let fileinput = null
                var reader = new FileReader();
                var vm = this;
                reader.onload = async (e) => {

                fileinput = reader.result;
                this.fileinput = fileinput
                let config = {}
                let data = []
                try {
                data = this.$papa.parse(this.fileinput,[config]);
                  
                } catch (error) {
                alert("Invalid CSV file Format")  
                }
                data = data.data
                this.csvData = {}
                this.errors = [];
                this.skipped = [];
                data.forEach(element => {
                  if (element.length == 2) {
                    let val = Math.round((Number(element[1])*10**this.DECIMALS)).toString(16)
                    let dc = val.split(".")
                    console.log("dc is", dc)

                    if (  isNaN(  Number(element[1])  )  ) {
                      let message = `Wallet ${element[0]} Does not Contains A Valid Amount, Entry Will Be Skipped`
                      this.errors.push(message)
                      this.skipped.push(element[0])
                    }
                    
                    else if (this.csvData[String(element[0])]) {
                      let message = `Wallet ${String(element[0])} Was found Duplicate, Entry Will Be Skipped"`
                      this.errors.push(message)
                      this.skipped.push(element[0])
                    }

                    else if (!web3.utils.isAddress(element[0])) {
                      let message = `Wallet Address ${element[0]} Was Found As Invalid Wallet Address, Entry Will Be Skipped`
                      this.errors.push(message)
                      this.skipped.push(element[0])
                    }else{
                        this.csvData[String(element[0])] = dc[0]
                    }

                  }
                });
                this.$bvModal.show("import-result")
                }
                reader.readAsText(file);
            },
    loadSampleImage(){
      this.$bvModal.show("sample-image")
    },
    async generateRoot(){
console.log(this.csvData)
      try {
      let data = JSON.stringify(parseBalanceMap(this.csvData))
      this.app.merkleData = data;
      this.app.currentStep = 2;
      let dec = await this.$store.dispatch("updateAppData", {app:this.app, next: 2})
      if (!dec.status) {
        alert("Failed to Generate the List, Server error")
      }
      } catch (error) {
        console.log(error)
        alert("Failed to Generate the List, Compliation error")
      }
    },
    setDays(value) { this.days = value},
    setHours(value) { this.hours = value},
    setMinutes(value) { this.minutes = value},
    setSeconds(value) { this.seconds = value},
    timer() {
      var x = setInterval(()=>{

      // Get today's date and time
      this.now = new Date().getTime();
        
      // Find the distance between now and the count down date
      let countDownDate = new Date(this.endTime).getTime()
      this.distance = countDownDate - this.now;
        
      // Time calculations for days, hours, minutes and seconds
      this.setDays(Math.floor(this.distance / (1000 * 60 * 60 * 24)));
      this.setHours(Math.floor((this.distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)));
      this.setMinutes(Math.floor((this.distance % (1000 * 60 * 60)) / (1000 * 60)));
      this.setSeconds(Math.floor((this.distance % (1000 * 60)) / 1000));
          
      // count down is over 
      if (this.distance < 0) {
        clearInterval(x);
        // alert("EXPIRED");
        this.expired = true;
      }
    }, 1000);
    },
    copy(text){
  var copyText = text

  navigator.clipboard.writeText(copyText);

  alert("Copied the text: " + copyText);
  

    },
    goTo(){
      window.open("https://claimelly.com", "_blank")
    },
    goTos(){
      window.open("https://hbsc-website-test-qelxg.ondigitalocean.app", "_blank")
    },
    runBquery(){
      this.$store.dispatch("Bquery");
    },
    async checkValue(){
      let val = Web3.utils.toChecksumAddress( this.vCheck ) ;
      let result = this.claimData.claims[ val ];
      if (result !== undefined) {
        let dec = await this.$store.dispatch("checkClaimStatus", val)
        let status = (dec) ? "Claimed" : "UnClaimed"
        console.log(result, dec)
        let amt = result.amount
        amt = (Number(amt) / this.DECIMALS)
        let message = "the wallet "+val+" has a Claim of "+amt+" "+this.symbol+"  and claim status is "+status+""
        alert(message)
      }else{
        let message = ' This wallet is not eligible for Claim'
        alert(message)
      }
      console.log(this.claimData.claims)
    },
    async withdrawNative(){
      this.$store.dispatch("withdrawNative",{sender: this.selectedAccount})
    },
    async withdraw() {
      console.log("running withdraw function")
      if(this.wAmount > 0 && this.wToken.length > 5){
        console.log(this.wAmount)
      let dec = await this.$store.dispatch('withdraw', {
        sender: this.selectedAccount,
        amount: this.wAmount,
        token: this.wToken
      })
      if (dec) {
          this.$store.dispatch('Bquery');
        // await this.$store.dispatch('BalanceCheck',  this.selectedAccount) ;
          this.wAmount = 0
          this.wToken = ''
      }
      }else{
        alert('please provide valid value and address')
      }
    },
      async withdrawAll(){
      let admin = this.admin
      let claimAccountBalance = this.claimAccountBalance
      let symbol = this.symbol
      let message = "Are you sure you want to withdraw "+claimAccountBalance+symbol+" to wallet "+admin
      console.log(message, admin, claimAccountBalance, symbol)
      let d = confirm(message)
      if (d) {
        let dec = await this.$store.dispatch("withdraw", {sender: this.selectedAccount, amount:claimAccountBalance})
      if (dec) {
        await this.$store.dispatch('contractBalance');
        await this.$store.dispatch('BalanceCheck',  this.selectedAccount) ;

      }  
      }
      
    },
    async updateAdmin(){
      await this.$store.dispatch("updateAdmin", {wallet:this.changeAdmin, sender: this.selectedAccount})
      this.$store.dispatch('getOwner');
       this.$store.dispatch('getAdmin');
       this.changeAdmin = '' 
    },
   
     onComplete(data) {
      this.$store.state.selectedAccount = data.metaMaskAddress
      console.log(data)
    },
    async fund() {
      await this.$store.dispatch('fundContract',{amount:this.fAmount})
      this.fAmount = 0
      await this.$store.dispatch("contractBalance");
    },
    async setProvider() {
      this.$store.state.provider = await detectEthereumProvider();
      if (this.$store.state.provider) {
        this.web3 = new Web3(this.$store.state.provider);
        this.$store.state.provider.enable();
        const chainId = await this.$store.state.provider.request({
            method: 'eth_chainId'
          })
        this.$store.state.provider.enable();

        console.log('chainID', chainId);
        window.ethereum.on('chainChanged', () => {
          this.connect()
        })
        window.ethereum.on('accountsChanged', () => {
          // window.location.reload();
          this.connect()
        })
        
        // 0x38 for mainnet
        // 0x61 for mainnet
        // if (chainId !== "0x38") {
        //   try {
        //   await window.ethereum.request({
        //     method: 'wallet_switchEthereumChain',
        //     params: [{ chainId: '0x38' }], // chainId must be in hexadecimal numbers
        //   });  
        //   } catch (error) {
        //     console.log("Error in changing chain", error)
        //     alert("Please Add BSC network in Meta Mask")
        //   }
          
        //   return false;
        // }
        this.$store.state.provider.enable();
        return true
      } else {
        console.log('Please install MetaMask!');
        return false;
      }
    },
    async connect(){
      let dec = await this.setProvider(); 
      console.log('set ptovidder result', dec)
      if (dec) {
        let user = null;
          let addresses = await window.ethereum.request({method: "eth_requestAccounts",});
              if(addresses.length>0){
                await web3.eth.getAccounts((error,result) => {
                  console.log(error, result)
                    if (error || result.length < 1) {
                        console.log(error, 'or no accounts');
                    } else {
                      console.log('setting up user account',result[0])
                      this.$store.state.selectedAccount = result[0]
                    // this.app.owner = result[0]
                    }
                }).then(async () => {
                    return

                  if(user){
                    console.log('found user', user)
                    this.$store.state.selectedAccount = user
                    this.app.owner = user
                    if (this.setupComplete) {
                      const chainId = await this.$store.state.provider.request({
                        method: 'eth_chainId'
                      })
                    if (chainId !== this.userAccount.selectedChain.chainId) {
                      try {
                      await window.ethereum.request({
                        method: 'wallet_switchEthereumChain',
                        params: [{ chainId: this.userAccount.selectedChain.chainId}],
                      });

                    // this.$store.dispatch("loadSymbol", this.appId)
                    // this.$store.dispatch('getOwner');
                    // this.$store.dispatch('getAdmin');
                    // this.$store.dispatch('contractBalance');
                    this.$store.dispatch('Bquery');
                    // this.$store.dispatch('BalanceCheck', user); 

                      } catch (error) {
                        console.log("Error in changing chain", error)
                        alert("Please Add "+this.userAccount.selectedChain.name+" in Meta Mask")
                      }
                      
                      return false;
                    }else{
                    this.$store.dispatch("loadSymbol", this.appId)
                    this.$store.dispatch('getOwner');
                    this.$store.dispatch('getAdmin');
                    // this.$store.dispatch('contractBalance');
                    this.$store.dispatch('Bquery');
                    // this.$store.dispatch('BalanceCheck', user); 
                    }
                     
                    }else{
                      this.registrationProcess() 
                    }
                  }else{
                    console.log(user,'user not found')
                  }
                })
                }
        }  
      
    },
    async registrationProcess(){
      // check wallet is in registered list
      // check how much steps completed
      // start the step required to run
      let registered = await this.$store.dispatch("checkWalletAccount", this.appId)
      if (registered) {
        
      }else{
        alert("Account Not Found")
      }
    },
    async claim(value) {
      if(window.ethereum)
      {
        let amt = value
        let dec = await this.$store.dispatch("checkClaimStatus", this.selectedAccount)
        console.log("result of claim status check is", dec)
        if (!dec) {
            let de = await this.$store.dispatch('hasClaimed', {account:this.selectedAccount,value:amt} )
            console.log('transaction result', de)    
        } else {
          alert("Already Claimed")
        }
      
      }else{
        console.log('not found meta mask')
        alert("Install Metamask")
      }
    },
    
   
  }
}
</script>

<style scoped>

@import url('https://fonts.googleapis.com/css2?family=Orbitron&display=swap');
.orbit{
font-family: 'Orbitron', sans-serif;

}
.btn-mode{
  right: 14px;
    top: -8px;
    padding: 6px;
    border-radius: 0px 33px 0px 11px;
}
.btn-mode:focus {
  outline: none;
  box-shadow: none;
}
.dark{
      background: black;
}
.bg-darks{
      background: transparent;
}
.light, .bg-whites{
      background: #FFF;
}
.text-whites{color:black;}
.text-darks{color:#FFF;}
.dark .shadow-cc{
    border-radius:33px;
    box-shadow: 0px -1px 19px #888888;
}
.dark .shadow-c{
  border: 0px !important;
    box-shadow: 3px 0px 9px 1px #888
}
.dark{
  border: 0px !important;
    box-shadow: 0px -1px 19px #888888;
}
.light{
  border: 0px !important;
    box-shadow: 0px -1px 19px lightslategrey;
}
.dark .input-group-text{
  border:0px !important;
}
.side-bar button{
  width: 100%;
    border: none;
    padding: 13px;
}
.side-bar button:hover{
  background: lightblue;
}
.side-bar button.active{
  background: #007bff;
  color:#FFF;
}
 /* 
  =========================
  Fonts
  =========================
  
  font-family: 'Outfit', sans-serif;
  
  */
  
  @import url('https://fonts.googleapis.com/css2?family=Outfit:wght@300;400;600&display=swap');
  
  /* 
  ========================
  Variables
  ========================
  */
  
  
  :root {
      
      font-size: 15px;
      
      /* Primary */
      --var-soft-blue: hsl(215, 51%, 70%);
      --var-cyan: hsl(178, 100%, 50%);
      /* Neutral */
      --var-main-darkest: hsl(217, 54%, 11%);
      --var-card-dark: hsl(216, 50%, 16%);
      --var-line-dark: hsl(215, 32%, 27%);
      --var-lightest: white;
      
      /* Fonts */
      
      --var-heading: normal normal 600 1.5em/1.6em 'Outfit', sans-serif;
      
      --var-small-heading: normal normal 400 1em/1em 'Outfit', sans-serif;
      
      --var-para: normal normal 300 1em/1.55em 'Outfit', sans-serif;
  }
  
  /* 
  =======================
  Setup
  =======================
  */
  
  html {
      box-sizing: border-box;
  }
  
  *, *::before, *::after {
      box-sizing: inherit;
      margin: 0;
  }
  
  .body {
      background-color: hsl(217, 54%, 11%);
  }
  
  img {
      width: 100%;
      border-radius: 15px 15px 0px 0px;
      display: block;
  }
  
  a {
      color: inherit;
  }
  
  /* 
  
  Eye view
  
  https://i.postimg.cc/9MtT4GZY/view.png' border='0' alt='view */
  
  /*
  =========================
  Font Styling
  =========================
  */
  
  h1 {
      font: normal normal 600 1.5em/1.6em 'Outfit', sans-serif;
      color: white;
      padding: 1.2em 0;
  }
  
  h2 {
      font: normal normal 400 1em/1em 'Outfit', sans-serif;
      color: white;
      /* padding on .coin-base */
  }
  
  p {
      font: normal normal 300 1em/1.55em 'Outfit', sans-serif;
      color: hsl(215, 51%, 70%);
  }
  
  span {
      /* color: white; */
  }
  /* 
  =====================
  Classes
  =====================
  */
  
  /* LAYOUT */
  
  .card-container {
      width: 100%;
      max-width: 400px;
      margin: 2em auto;
      background-color: hsl(216, 50%, 16%);
      border-radius: 15px;
      margin-bottom: 1rem;
      padding: 1rem;
  }
  
  div.flex-row {
      display: flex;
      justify-content: space-between;
      align-items: center;
  }
  
  div.coin-base, .time-left, .card-attribute {
      display: flex;
      align-items: center;
      padding: 1em 0;
  }
  
  .card-attribute {
      padding-bottom: 1.5em;
      border-top: 2px solid hsl(215, 32%, 27%);
  }
  
  a.hero-image-container {
      position: relative;
      display: block;
  }
  
  
  
  /* Details */
  
  img.eye {
      position: absolute;
      width: 100%;
      max-width: 2em;
      top: 44%;
      left: 43%;
  }
  
  @media (min-width:400px) {
    img.eye {
      max-width: 3em;
  }
  }
  
  .hero-image-container::after {
      content: '';
      background-image: url("https://i.postimg.cc/9MtT4GZY/view.png");
      background-position: center;
      background-repeat: no-repeat;
      background-size: 5rem;
      background-color: hsla(178, 100%, 50%, 0.3);
      width: 100%;
      height: 100%;
      border-radius: 1rem;
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      z-index: 0;
      opacity: 0;
      transition: opacity 0.3s ease-out;
  }
  
  .hero-image-container:hover::after {
    opacity: 1;
  }
  
  .small-image {
      width: 1.2em;
      margin-right: .5em;
  }
  
  .small-avatar {
      width: 2em;
      border-radius: 200px;
      outline: 2px solid white;
      margin-right: 1.4em;
  }
  
  div.attribution {
      margin: 0 auto;
      width: 100%;
      font: normal normal 300 1em/1.55em 'Outfit', sans-serif;
      text-align: center;
      padding: 1.5em 0 4em 0;
      color: hsl(215, 32%, 27%);
  }
  .attribution a {
      color: hsl(215, 51%, 70%);
  }
  
  @media (min-width:600px) {
      .body {
          font-size: 18px;
      }
  }
  .btn-primary{
    font-family: "Calibri";
  
    background-color: #b99653 !important;
    border:1px solid #b99653 !important;
  }
  .fixed-container {
  width: 355px;
  height: 400px;
  overflow: hidden;
  position: relative;
}

.fixed-container img,
.fixed-container video {
  width: 100%;
  height: 100%;
  object-fit: cover; /* This ensures the content is either cropped or zoomed to fit within the container */
}

.scrollable-container {
  position: relative;
  width: 100%;
  overflow: hidden;
  height: 400px;
}

.scrollable-content {
  white-space: nowrap;
  display: inline-block;
  transition: transform 0.3s;
  /* transform: scale(0.9) */
}

.scroll-button {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 40px;
  background: #FFF;
  /* color: black; */
  font-size: 45px;
  border: none;
  cursor: pointer;
  z-index: 999999;
}

.scroll-button.lefts {
  left: 0px;
    border: 1px solid gray;
    height: 50px;
    top: 175px;
}

.scroll-button.rights {
  right: 0px;
    border: 1px solid gray;
    height: 50px;
    top: 175px;
}
</style>